<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar
      flat
    >
      <v-menu
        v-model="menu"
        close-on-content-click
        transition="scale-transition"
        min-width="290px"
      >
        <v-text-field
          slot="activator"
          v-model="monthOf"
          prepend-icon="event"
          readonly
        />
        <v-date-picker
          v-model="monthOf"
          :max="maxDate"
          :allowed-dates="allowedMonths"
          :min="startOfReport"
          type="month"
          year-icon="date_range"
        />
      </v-menu>
      <v-spacer />
      <v-select
        v-model="filter"
        :items="['OneXOX','XOX']"
        label="Filter by"
        clearable
      />
      <v-spacer />
      <v-btn-toggle v-model="showMode">
        <v-btn
          flat
          value="byPlan"
        >
          View By Plan
        </v-btn>
        <v-btn
          flat
          value="byFc"
        >
          View By FC
        </v-btn>
      </v-btn-toggle>
    </v-toolbar>
    <v-data-table
      :headers="activeData.summaryHeaders"
      :items="activeItems"
      :expand="expand"
      item-key="day"
      hide-actions
    >
      <template #items="props">
        <tr @click="props.expanded = !props.expanded">
          <td
            v-for="(value, key) in props.item"
            :key="key"
            :class="tableClass(key)"
          >
            {{ value }}
          </td>
        </tr>
      </template>
      <template #expand="props">
        <v-card
          class="px-3 pb-4"
          color="grey darken-2"
        >
          <v-data-table
            :headers="getDetailHeaders(props.index)"
            :items="getDetailItems(props.index)"
            item-key="total"
            class="elevation-3"
            hide-actions
          >
            <template #items="propsDetail">
              <tr class="grey darken-1">
                <td
                  v-for="(value, key) in propsDetail.item"
                  :key="key"
                  :class="subTableClass(key)"
                >
                  {{ value === null ? '-' : value }}
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </template>
      <template #footer>
        <td
          v-for="(value, key) in activeData.summaryFooter"
          :key="key"
          class="text-xs-center primary"
        >
          {{ value === null ? `&nbsp;` : value }}
        </td>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { DateTime, Interval } from 'luxon'
import { createGetParams } from '@/rest'
const reportTitle = 'Daily FC Activation'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      activeData: { summaryItems: [] },
      breadcrumbs: [
        {
          text: 'Management Report', disabled: false, to: '/management/management_kits',
        },
        {
          text: 'Daily Excerpt', disabled: false, to: '/management/daily_view',
        },
        {
          text: 'Daily FC Activation', disabled: true,
        },
      ],
      expand: false,
      filter: '',
      loading: false,
      menu: false,
      maxDate: '',
      month: null,
      monthOf: null,
      result: null,
      showMode: null,
      startOfReport: '2023-01',
      title: reportTitle,
    }
  },
  computed: {
    activeItems: function () {
      const items = []
      this.activeData.summaryItems.forEach(item => {
        items.push(item.summary)
      })

      return items
    },
    tableHighlightColor: function () {
      if (this.result.meta.tableHighlightColor) {
        return this.result.meta.tableHighlightColor
      }
      return 'orange'
    },
    subTableHighlightColor: function () {
      if (this.result.meta.subTableHighlightColor) {
        return this.result.meta.subTableHighlightColor
      }
      return 'amber'
    },
  },
  watch: {
    showMode: function (val) {
      if (val === 'byPlan') {
        this.activeData = this.result.plan
      } else if (val === 'byFc') {
        this.activeData = this.result.fc
      }
    },
    monthOf: function (val) {
      this.getActivations(val)
    },
    filter: function (val) {
      this.getActivations(this.monthOf)
    },
  },
  mounted: function () {
    const now = DateTime.local().setZone('Asia/Kuala_Lumpur')
    this.maxDate = this.monthOf = now.toFormat('yyyy-LL')
  },
  methods: {
    allowedMonths: function (month) {
      const ym = month.split('-')
      const whatMonth = DateTime.local(Number(ym[0]), Number(ym[1])).setZone('Asia/Kuala_Lumpur')
      const fromDate = DateTime.local(2023, 1, 1).setZone('Asia/Kuala_Lumpur')
      const now = DateTime.local().setZone('Asia/Kuala_Lumpur')

      return Interval.fromDateTimes(fromDate, now).contains(whatMonth)
    },
    getActivations (month) {
      this.loading = true
      const localParams = {
        month,
      }
      if (this.filter) {
        Object.assign(localParams, { claimer: this.filter })
      }
      const params = createGetParams(localParams)
      this.showMode = null
      this.$rest.get('getDailyFcActivationsOfMonth.php', params)
        .then(function (response) {
          this.result = response.data
          this.showMode = 'byPlan'
          if (this.result.meta) {
            this.startOfReport = this.result.meta.startOfReport
          }
          this.loading = false
        }.bind(this)).catch(error => {
          this.noDataText = error.message
          this.loading = false
        })
    },
    tableClass (key) {
      const alignment = key === 'fc' ? 'text-xs-left ' : 'text-xs-center '
      return alignment + (key === 'total' ? this.tableHighlightColor : ' white--text')
    },
    subTableClass (key) {
      const alignment = key === 'fc' ? 'text-xs-left ' : 'text-xs-center '
      const thisClass = alignment + (key === 'total' ? this.subTableHighlightColor : ' white--text')
      return thisClass
    },
    getDetailHeaders (index) {
      return this.activeData.summaryItems[index].detailHeaders
    },
    getDetailItems (index) {
      return this.activeData.summaryItems[index].items
    },
  },
}
</script>
